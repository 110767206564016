import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

const PageLoader = () => {
  return (
    <Row>
      <Col className="text-align">
        <Spinner animation="border" role="status"/>
      </Col>
    </Row>
  )
}

export default PageLoader
