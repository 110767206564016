import { getBabyDogeContract } from "utils/contractHelper";
import {
  balance,
  getRateOfPool,
  getCurrentPrice,
  Volume24,
  VolumeMonth,
  Volume7,
  MarketCap,
  EstimatedFutureReflections,
  totalSupply,
  balanceWithoutRewardsFunction,
  getCirculatingSupply,
  getReflectionInfo,
  DEAD_ADDRESS,
} from "utils/callHelper";
import { getPairV2 } from "utils/pair";
import { provider, archProvider } from "utils/provider";
import { ethers } from "ethers";

export const EstimatedFutureReflectionsAsync = async (
  days,
  multiplier,
  volumePast24,
  volumePastWeek,
  volumePastMonth,
  dailyRate,
  currentPrice
) => {
  let futureVolume = await EstimatedFutureReflections(
    days,
    multiplier,
    volumePast24,
    volumePastWeek,
    volumePastMonth
  );

  let futureReflections = (futureVolume / currentPrice) * 0.05;
  let futureUserReflections = (futureReflections * dailyRate).toFixed(2); // Estimated BabyDoge Gained (16)
  let futureUserValue = (futureUserReflections * currentPrice).toFixed(2); // Estimated USD Value (17)

  return { futureUserReflections, futureUserValue };
};

export const fetchUserData = async (account, days, multiplier) => {
  const babyDogeContract = getBabyDogeContract(provider);
  let url =
    "https://api.bscscan.com/api?module=account&action=tokentx&contractaddress=" +
    "0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50" +
    "&address=" +
    account +
    "&page=1&offset=10000&sort=asc&apikey=7UG6HX1VNW76Z8FK2A9B5FTHU9QCQM9W68";

  var tokenlist = "";

  const myRequest = new Request(url, {
    //method: 'GET',
    //headers: myHeaders,
    //mode: 'cors',
    cache: "default",
  });

  await fetch(myRequest)
    .then((response) => response.json())
    .then((json) => {
      tokenlist = json;
    });

  let transactions = tokenlist.result;
  console.log(transactions.length);
  let allBuys = 0;
  let allSells = 0;

  for (let i = 0; transactions.length - 1 >= i; i++) {
    if (
      ethers.utils.getAddress(tokenlist.result[i].from) ===
      ethers.utils.getAddress(account)
    ) {
      allSells +=
        parseInt(tokenlist.result[i].value) +
        parseInt(tokenlist.result[i].value) / 10;
      console.log(tokenlist.result[i]);
    } else if (
      ethers.utils.getAddress(tokenlist.result[i].to) ===
      ethers.utils.getAddress(account)
    ) {
      allBuys += parseInt(tokenlist.result[i].value);
      console.log(tokenlist.result[i]);
    }
  }

  //0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50
  //test address
  let pairAddress = await getPairV2();

  let currentPrice = await getCurrentPrice(); // Price
  let currentBalance = await balance(babyDogeContract, account);
  //let currentBalanceInt = currentBalance// Your BabyDoge Balance (1)
  let currentBalanceUSD = (currentBalance * currentPrice).toFixed(2); // Your BabyDoge USD Value (2)
  let finalBuys = allBuys / 10 ** 9;
  let finalSells = allSells / 10 ** 9 + allSells / 10 ** 9 / 10;
  let finalDiff = finalBuys - finalSells;
  let volumePast24 = await Volume24(); // 24-hour volume (5)
  let marketcap = await MarketCap(babyDogeContract, currentPrice); // Market Cap (4)
  let volumePastMonth = await VolumeMonth(); // Monthly Volume (6)
  //let totalReflections =Math.abs(parseInt(currentBalance) - ((finalBuys - ((finalSells/90) * 100))));
  let totalReflections = Math.abs(currentBalance - (finalBuys - finalSells));

  let totalReflectionsValue = (totalReflections * currentPrice).toFixed(2); // Total USD Value Earned (8)
  //let BalanceWithoutReflections =  currentBalance - totalReflections;
  let volumePastWeek = await Volume7();
  let percentOfOwnership = await getRateOfPool(
    babyDogeContract,
    account,
    pairAddress
  );
  //let volumeTokenReflectionsDaily = (volumePast24 / currentPrice) * .05;
  let volumeTokenReflectionsWeekly = (volumePastWeek / currentPrice) * 0.05;
  let volumeTokenReflectionsMonth = (volumePastMonth / currentPrice) * 0.05;

  let weeklyEarnedPredication =
    percentOfOwnership * volumeTokenReflectionsWeekly; // Projected Weekly BabyDoge Rewards (9)
  let monthlyEarnedPrediciton =
    percentOfOwnership * volumeTokenReflectionsMonth; // Projected Monthly BabyDoge Rewards (11)
  //let monthlyEarnedPrediciton = dailyRate * volumeTokenReflectionsMonth // Projected Monthly BabyDoge Rewards (11)

  let weeklyEarnedUsdPrediction = (
    weeklyEarnedPredication * currentPrice
  ).toFixed(2); //Projected Weekly USD Rewards (10)
  let monthlyEarnedUsdPrediction = (
    monthlyEarnedPrediciton * currentPrice
  ).toFixed(2); //Projected Monthly USD Rewards (12)

  const { futureUserReflections, futureUserValue } =
    await EstimatedFutureReflectionsAsync(
      days,
      multiplier,
      volumePast24,
      volumePastWeek,
      volumePastMonth,
      percentOfOwnership,
      currentPrice
    );

  let supply = await totalSupply(babyDogeContract);

  let dayVolumeTokens = volumePastMonth / 30 / currentPrice;
  let reflectPercent = (dayVolumeTokens * 0.05) / (supply / 10 ** 9);

  let apy = ((Math.pow(1 + reflectPercent, 365) - 1) * 100).toFixed(2); // APY (Annual Percentage Yield)

  let balanceWithoutRewards = balanceWithoutRewardsFunction(
    currentBalance,
    totalReflections
  );
  let ifNeverSold = ((finalBuys + totalReflections) * currentPrice).toFixed(2); // If you never sold any of your BabyDoge, now they would be worth (21)
  let ifNeverSoldTokens = finalBuys + totalReflections;

  console.log("final difference", finalDiff);
  console.log("current balance", currentBalance);
  console.log("balanceType", typeof currentBalance);
  console.log("reflections: ", totalReflections);
  console.log("all buys:", finalBuys);
  console.log("all Sels", finalSells);
  console.log("allbuys - allsells", finalBuys - finalSells);
  console.log("ifNeverSoldTokens", ifNeverSoldTokens);
  console.log("allBuys + reflections", totalReflections + allBuys);
  console.log("volume data", await VolumeMonth());

  return {
    price: currentPrice,
    dailyRate: percentOfOwnership,
    doge: {
      doge_balance: currentBalance,
      usd_balance: currentBalanceUSD,
      price: currentPrice,
      market_cap: marketcap,
      d_volume: volumePast24,
      w_volume: volumePastWeek,
      m_volume: volumePastMonth,
    },
    rewards: {
      total_doge_earned: totalReflections,
      total_usd_earned: totalReflectionsValue,
      weekly_doge_reward: weeklyEarnedPredication.toFixed(2),
      monthly_doge_reward: monthlyEarnedPrediciton.toFixed(2),
      weekly_usd_reward: weeklyEarnedUsdPrediction,
      monthly_usd_reward: monthlyEarnedUsdPrediction,
      apy: apy,
    },

    prediction: {
      doge_gained: futureUserReflections,
      estimated_usd_value: futureUserValue,
    },

    activity: {
      doge_in: finalBuys + totalReflections,
      doge_out: finalSells,
      doge_balance_without_rewards: balanceWithoutRewards,
      doge_worth: ifNeverSold,
    },
  };
};

export const fetchTokenData = async () => {
  const babyDogeContract = getBabyDogeContract(archProvider);

  const blockNumberHour = 1200;
  const blockNumberDay = 28800;
  const blockNumberWeek = 28800 * 7;
  const blockNumberMonth = 864000;

  const deadBalance = await balance(babyDogeContract, DEAD_ADDRESS);
  const totalSupplyValue = await totalSupply(babyDogeContract);

  const [
    circulatingSupply,
    currentPrice,
    hourReflection,
    //delay3,
    dayReflection,
    //delay1,
    weekReflection,
    //delay4,
    monthReflection,
    //delay2,
  ] = await Promise.all([
    getCirculatingSupply(babyDogeContract, deadBalance),
    getCurrentPrice(),
    getReflectionInfo(babyDogeContract, blockNumberHour),
    //await delay(1001),
    getReflectionInfo(babyDogeContract, blockNumberDay),
    //await delay(1001),
    getReflectionInfo(babyDogeContract, blockNumberWeek),
    //await delay(1001),
    getReflectionInfo(babyDogeContract, blockNumberMonth),
    //await delay(1001),
  ]);

  console.log("week", weekReflection);
  console.log("day", dayReflection);
  //console.log(delay1, delay2, delay3, delay4);
  const nextReflection = monthReflection * 1.2;
  const secReflection = hourReflection / 60 / 60;

  console.log("24Hour Reflectoin", dayReflection);
  console.log("sec Reflectoin", secReflection);

  const secReflectionUsd = (secReflection * parseFloat(currentPrice)).toFixed(
    2
  );

  const hourReflectionUsd = (hourReflection * parseFloat(currentPrice)).toFixed(
    2
  );
  const dayReflectionUsd = (dayReflection * parseFloat(currentPrice)).toFixed(
    2
  );
  const weekReflectionUsd = (weekReflection * parseFloat(currentPrice)).toFixed(
    2
  );
  const monthReflectionUsd = (
    monthReflection * parseFloat(currentPrice)
  ).toFixed(2);
  const nextReflectionUsd = (nextReflection * parseFloat(currentPrice)).toFixed(
    2
  );

  return {
    token: {
      circulatingSupply: circulatingSupply.toString(),
      burnt: deadBalance.toString(),
      totalSupply: (totalSupplyValue / 10 ** 9).toFixed(0),
      secReflection,
      hourReflection,
      dayReflection,
      weekReflection,
      monthReflection,
      nextReflection,
      secReflectionUsd,
      hourReflectionUsd,
      dayReflectionUsd,
      weekReflectionUsd,
      monthReflectionUsd,
      nextReflectionUsd,
    },
  };
};

export const fetchTokenSupplyData = async () => {
  const babyDogeContract = getBabyDogeContract(provider);
  const deadBalance = await balance(babyDogeContract, DEAD_ADDRESS);
  const circulatingSupply = await getCirculatingSupply(
    babyDogeContract,
    deadBalance
  );

  return {
    token: {
      circulatingSupply: circulatingSupply.toString(),
      burnt: deadBalance.toString(),
    },
  };
};
