import { ethers } from "ethers";

import { BabyDogeAddress } from "config/abi/babydoge";
import provider from "utils/provider";

const PancakeSwapAbi = [
  "function getPair(address tokenA, address tokenB) external view returns (address pair)",
];

const factoryAddressV2 = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";

const bnbAddress = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";

export const getPairV2 = async () => {
  const factoryContractV2 = new ethers.Contract(
    factoryAddressV2,
    PancakeSwapAbi,
    provider
  );

  let pairV2 = await factoryContractV2.getPair(bnbAddress, BabyDogeAddress).then();
 
  return pairV2;
};

