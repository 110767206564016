import React, { useCallback, useEffect } from 'react'
import { Card, Row, Col, Image, Form } from 'react-bootstrap'

import Divider from 'components/Divider'
import Tooltip from 'components/ToolTip'

import { formatNumber } from 'utils/formatBalance'
import { VOLUME_MULTIPLIER } from 'config/constants'
import { EstimatedFutureReflectionsAsync } from 'store/user/fetchUser'
import { useSetUser, usePrediction } from 'store/hooks'

const Prediction = ({ prediction, price, dailyRate, doge }) => {
  const { days, multiplier } = usePrediction()

  const { setPrediction, setDays, setMultiplier } = useSetUser()

  useEffect(() => {
    const EstimatedFutureReflections = async () => {
      const { futureUserReflections, futureUserValue } = await EstimatedFutureReflectionsAsync(
        days,
        multiplier,
        doge.d_volume,
        doge.w_volume,
        doge.m_volume,
        dailyRate,
        price
      )
      setPrediction(futureUserReflections, futureUserValue)
    }
    EstimatedFutureReflections()
  }, [days, multiplier, doge, dailyRate, price, setPrediction])

  const updateDays = useCallback((v) => {
    setDays(v)
  }, [setDays])

  const updateMutiplier = useCallback((v) => {
    setMultiplier(v)
  }, [setMultiplier])

  return (
    <div className="height-100 text-align mx-2 my-4">
      <Row>
        <Col className="text-align">
          <Image
            src=""
            width="96"
            alt="Icon Prediction"
            rounded />
        </Col>
        <div className="doge__title">Prediction Maker</div>
        <Row>
          <Col className="doge__sub-title">
            Curious about the future? Use the Prediction Maker to enter different scenarios for your future BabyDoge rewards!
          </Col>
        </Row>
      </Row>
      <Card className="doge__card same-height">
        <Row className="my-2">
          <Col>
            <div className="text-align property">Days into the future</div>
            <div className="text-align doge__price d-flex justify-content-center">
              <Form.Control
                type="number"
                className="prediction-maker"
                placeholder="Days into the future"
                value={days}
                max="365"
                min="1"
                step="1"
                onChange={(e) => updateDays(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <div className="text-align property">Estimated BabyDoge Gained</div>
            <div className="text-align doge__price">{formatNumber(prediction.doge_gained)}</div>
          </Col>
        </Row>

        <Divider/>

        <Row className="my-2">
          <Col>
            <div className="text-align property">
              BabyDoge Volume Multiplier
              <Tooltip placement="bottom" content={VOLUME_MULTIPLIER} />
            </div>
            <div className="text-align doge__price d-flex justify-content-center">
              <Form.Control
                className="prediction-maker"
                type="number"
                placeholder="Volume Multiplier"
                value={multiplier}
                max="10"
                min="0.1"
                step="0.1"
                onChange={(e) => updateMutiplier(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <div className="text-align property">Estimated USD Value</div>
            <div className="text-align doge__price">${formatNumber(prediction.estimated_usd_value)}</div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default Prediction
