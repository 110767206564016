import React from "react";
import { Card, Row, Col } from "react-bootstrap";

import Divider from "components/Divider";

import { formatNumber } from "utils/formatBalance";

const BabyDogeInfo = ({ doge, account }) => {
  return (
    <Row className="doge text-align mx-2 my-4">
      <Card className="doge__card">
        <div className="doge__title">Wallet ID:</div>
        <div className="doge__title">{account}</div>
        <Divider />
        <Row xs="1" sm="2" className="m-2">
          <Col>
            <div className="text-align property">Baby Doge balance</div>
            <div className="text-align doge__price">
              {doge.doge_balance.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </Col>
          <Col>
            <div className="text-align property">USD value</div>
            <div className="text-align doge__price">
              ${formatNumber(doge.usd_balance)}
            </div>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default BabyDogeInfo;
