import React, { useCallback, useState } from 'react'

import { Card, Row, Button, Form, Image } from 'react-bootstrap'

import { useSetUser, useToast } from 'store/hooks'

import { validationAddress } from 'utils/address'

const Tracker = ({setShow}) => {
  const [address, updateAddress] = useState(null)
  const { setAddress } = useSetUser()

  const { toastError } = useToast()

  const handleClick = useCallback(() => {
    // Check Address Type
    if (validationAddress(address)) {
      setAddress(address)
      setShow(false)
    }
    else {
      toastError('Address is not valid')
    }
  }, [address, setAddress, setShow, toastError])

  return (
    <div className="tracker">
      <Image src="" className="tracker__logo"/>
      <div className="tracker__header">REWARDS TRACKER</div>
      <div className="tracker__sub-title">Enter your BabyDoge wallet address below to get started.</div>
      <Card className="tracker__card">
        <Card.Body>
          <Row>
            <Form.Control className="tracker__input" placeholder="0xAABB..." value={address} onChange={(e) => updateAddress(e.target.value)}/>
          </Row>
          <Row>
            <Button className="tracker__button" variant="primary" onClick={handleClick}>START TRACKING</Button>
          </Row>
        </Card.Body>
      </Card>
      <div className="tracker__footer">
      Refreshing the page will cause it to lose your stats this data is not stored in our server, it is fetched directly from the network in real-time. This tool is for educational purposes only and we do not guarantee accurate data. Do not make decisions based off data presented. Baby Doge LLC nor any of its community or members will be responsible for any use, misuse, or decisions made from tracker. 
      </div>
    </div>
  )
}

export default Tracker
