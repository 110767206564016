import React from "react";

import { Card, Row, Col, Image } from "react-bootstrap";

import { formatNumber } from "utils/formatBalance";

const DogeTracker = ({ doge }) => {
  return (
    <Row className="doge mx-2 my-4">
      <Col className="text-align">
        <Image
          src=""
          width="200"
          alt="BabeDoge"
          rounded
        />
      </Col>
      <div className="doge__title">BabyDoge Tracker</div>
      <Card className="doge__card">
        <Row>
          <Col className="text-align property">Your BabyDoge Balance</Col>
        </Row>
        <Row>
          <Col className="text-align doge__balance">
            {formatNumber(doge.doge_balance)}
          </Col>
        </Row>
        <Row>
          <Col className="text-align property">Your BabyDoge USD Balance</Col>
        </Row>
        <Row className="m-2">
          <Col className="text-align doge__usd_balance">
            ${formatNumber(doge.usd_balance)}
          </Col>
        </Row>

        <Row className="m-2">
          <Col className="text-align doge__token-stats">
            BabyDoge Token Stats
          </Col>
        </Row>
        <Row xs="1" sm="2">
          <Col className="my-2">
            <div className="text-align property">Price</div>
            <div className="text-align doge__price">${doge.price}</div>
          </Col>
          <Col className="my-2">
            <div className="text-align property">Market Cap</div>
            <div className="text-align doge__price">
              ${formatNumber(doge.market_cap)}
            </div>
          </Col>
        </Row>
        <Row xs="1" sm="2">
          <Col className="my-2">
            <div className="text-align property">24-hour Volume</div>
            <div className="text-align doge__price">
              ${formatNumber(doge.d_volume)}
            </div>
          </Col>
          <Col className="my-2">
            <div className="text-align property">Monthly Volume</div>
            <div className="text-align doge__price">
              ${formatNumber(doge.m_volume)}
            </div>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default DogeTracker;
