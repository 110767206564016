import React from "react";
import { Card, Row, Col } from "react-bootstrap";

import { formatNumber } from "utils/formatBalance";
import Balance from "components/Balance";
import Skeleton from "components/Skeleton";

const Tokenomics = ({ token }) => {
  return (
    <Row className="doge mx-2 my-4">
      <Card className="doge__card">
        <div className="doge__title">Tokenomics</div>
        <div className="p-0 prediction-activity">
          <Col className="p-0 flex-2">
            <Row className="my-2">
              {/* <Col xs={4}>
                <div className="property text-align-left">Contract-Address</div>
              </Col>
              <Col xs={8}>
                <div className="property text-align-left">
                  {BabyDogeAddress}
                </div>
              </Col> */}
            </Row>
            <Row className="my-2">
              <Col xs={4} className="px-1">
                <div className="property text-align-left">Reflection</div>
              </Col>
              <Col xs={8}>
                {" "}
                <div className="property text-align-left">5%</div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col xs={4} className="px-1">
                <div className="property text-align-left">
                  Circulating supply
                </div>
              </Col>
              <Col xs={8}>
                {token.circulatingSupply ? (
                  <Balance
                    value={parseFloat(token.circulatingSupply)}
                    unit=" Baby Doge"
                    color="#0ab5db"
                    duration={4}
                  />
                ) : (
                  <Skeleton />
                )}
              </Col>
            </Row>
            <Row className="my-2">
              <Col xs={4} className="px-1">
                <div className="property text-align-left">Burnt</div>
              </Col>
              <Col xs={8}>
                {token.burnt ? (
                  <Balance
                    value={parseFloat(token.burnt)}
                    unit=" Baby Doge"
                    color="#0ab5db"
                    duration={4}
                  />
                ) : (
                  <Skeleton />
                )}
              </Col>
            </Row>
            <Row className="my-2">
              <Col xs={4} className="px-1">
                <div className="property text-align-left">Total Supply</div>
              </Col>
              <Col xs={8}>
                <Balance
                  value={420000000000000000}
                  unit=" Baby Doge"
                  color="#0ab5db"
                  duration={1}
                />
              </Col>
            </Row>
          </Col>

          <Col className="flex-1">
            <Row className="my-2">
              <Col className="px-1">
                <div className="property text-align-left">Reflection/Sec</div>
              </Col>
              <Col className="px-1 flex">
                <div className="property text-align-left default-color">
                  {formatNumber(token.secReflection)}
                </div>
                <div className="property text-align-left">
                  US ${formatNumber(token.secReflectionUsd)}
                </div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col className="px-1">
                <div className="property text-align-left">Last hour:</div>
              </Col>
              <Col className="px-1 flex">
                <div className="property text-align-left default-color">
                  {formatNumber(token.hourReflection)}
                </div>
                <div className="property text-align-left">
                  US ${formatNumber(token.hourReflectionUsd)}
                </div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col className="px-1">
                <div className="property text-align-left">Last 24 hours</div>
              </Col>
              <Col className="px-1 flex">
                <div className="property text-align-left default-color">
                  {formatNumber(token.dayReflection)}
                </div>
                <div className="property text-align-left">
                  US{formatNumber(token.dayReflectionUsd)}
                </div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col className="px-1">
                <div className="property text-align-left">Last week</div>
              </Col>
              <Col className="px-1 flex">
                <div className="property text-align-left default-color">
                  {formatNumber(token.weekReflection * 5)}
                </div>
                <div className="property text-align-left">
                  US ${formatNumber(token.weekReflectionUsd * 5)}
                </div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col className="px-1">
                <div className="property text-align-left">Last month</div>
              </Col>
              <Col className="px-1 flex">
                <div className="property text-align-left default-color">
                  {formatNumber(token.monthReflection)}
                </div>
                <div className="property text-align-left">
                  US ${formatNumber(token.monthReflectionUsd)}
                </div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col className="px-1">
                <div className="property text-align-left">Next month</div>
              </Col>
              <Col className="px-1 flex">
                <div className="property text-align-left default-color">
                  {formatNumber(token.nextReflection)}
                </div>
                <div className="property text-align-left">
                  US ${formatNumber(token.nextReflectionUsd)}
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </Card>
    </Row>
  );
};

export default Tokenomics;
