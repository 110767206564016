/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

import { fetchUserData, fetchTokenData, fetchTokenSupplyData } from './fetchUser'

const initialState = {
  address: null,
  price: null,
  dailyRate: null,
  doge: {
    doge_balance: null,
    usd_balance: null,
    price: null,
    market_cap: null,
    d_volume: null,
    w_volume: null,
    m_volume: null,
  },

  rewards: {
    total_doge_earned: null,
    total_usd_earned: null,
    weekly_doge_reward: null,
    monthly_doge_reward: null,
    weekly_usd_reward: null,
    monthly_usd_reward: null,
    apy: null,
  },

  prediction: {
    doge_gained: null,
    estimated_usd_value: null,
  },

  activity : {
    doge_in: null,
    doge_out: null,
    doge_balance_without_rewards: null,
    doge_worth: null
  },

  days: 7,
  multiplier: 1,
  isLoading: true,

  token: {
    circulatingSupply: null,
    burnt: null,
    totalSupply: null,
    secReflection: null,
    hourReflection: null,
    dayReflection: null,
    weekReflection: null,
    monthReflection: null,
    nextReflection: null,
    secReflectionUsd: null,
    hourReflectionUsd: null,
    dayReflectionUsd: null,
    weekReflectionUsd: null,
    monthReflectionUsd: null,
    nextReflectionUsd: null,
  }
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload
    },
    setDays: (state, action) => {
      state.days = action.payload
    },
    setMultiplier: (state, action) => {
      state.multiplier = action.payload
    },
    setPrediction: (state, action) => {
      state.prediction = action.payload
    },
    setUserData: (state, action) => {
      const { price, dailyRate, doge, rewards, prediction, activity } = action.payload
      state.price = price
      state.dailyRate = dailyRate
      state.doge = doge
      state.rewards = rewards
      state.prediction = prediction
      state.activity = activity
      state.isLoading = false
    },
    setTokenSupplyData: (state, action) => {
      const { token } = action.payload
      state.token.circulatingSupply = token.circulatingSupply
      state.token.burnt = token.burnt
    },
    setTokenData: (state, action) => {
      const { token } = action.payload
      state.token = token
    },
    setInitialState: state => initialState
  },
})

// Actions
export const { 
  setAddress, 
  setDays, 
  setMultiplier, 
  setPrediction, 
  setUserData, 
  setInitialState,
  setTokenData,
  setTokenSupplyData
} = userSlice.actions

// Thunks
export const fetchUserDataAsync = (address, days, multiplier) => async (dispatch) => {
  const userData = await fetchUserData(address, days, multiplier)
  dispatch(setUserData(userData))
}

export const fetchTokenDataAsync = () => async (dispatch) => {
  const tokenData = await fetchTokenData()
  dispatch(setTokenData(tokenData))
}

export const fetchTokenSupplyDataAsync = () => async (dispatch) => {
  const data = await fetchTokenSupplyData()
  dispatch(setTokenSupplyData(data))
}

export default userSlice.reducer
