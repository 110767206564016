import React from 'react'
import { Card, Row, Col, Image } from 'react-bootstrap'

import Divider from 'components/Divider'
import Tooltip from 'components/ToolTip'

import { formatNumber } from 'utils/formatBalance'
import { DOGE_IN, DOGE_OUT, WITHOUT_REWARDS } from 'config/constants'

const Activity = ({ activity }) => {
  return (
    <div className="height-100 text-align mx-2 my-4">
      <Row>
        <Col className="text-align">
          <Image
            src=""
            width="96"
            alt="Icon Wallet"
            rounded />
        </Col>
        <div className="doge__title">Wallet Activity</div>
        <Row>
          <Col className="doge__sub-title">
            Curious about the past? This will show your all time BabyDoge states including total tokens in and out of this address.
          </Col>
        </Row>
      </Row>
      <Card className="doge__card same-height">
        <Row xs="1" sm="2" className="m-2">
          <Col>
            <div className="text-align property">
              Total BabyDoge In
              <Tooltip placement="bottom" content={DOGE_IN}/>
            </div>
            <div className="text-align doge__price">{formatNumber(activity.doge_in)}</div>
          </Col>
          <Col>
            <div className="text-align property">
              Total BabyDoge Out
              <Tooltip placement="bottom" content={DOGE_OUT}/>
            </div>
            <div className="text-align doge__price">{formatNumber(activity.doge_out)}</div>
          </Col>
        </Row>

        <Divider/>

        <Row className="m-2">
          <Col>
            <div className="text-align property">
              BabyDoge Balance Without Rewards
              <Tooltip placement="bottom" content={WITHOUT_REWARDS}/>
            </div>
            <div className="text-align doge__price">{formatNumber(activity.doge_balance_without_rewards)}</div>
          </Col>
        </Row>
        <Row className="m-2">
          <Col>
            <div className="text-align property">If you never sold any of your BabyDoge, now they would be worth</div>
            <div className="text-align doge__price">${formatNumber(activity.doge_worth)}</div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default Activity
