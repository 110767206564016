import React from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'

import ToastListener from 'components/ToastListener'
import PAW from 'views/Paw'

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={PAW} />
        <Redirect from="*" to="/" />
      </Switch>
      <ToastListener />
    </BrowserRouter>
  )
}

export default React.memo(App)
