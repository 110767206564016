function kFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
}
function mFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M' : Math.sign(num) * Math.abs(num)
}
function bFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000000).toFixed(1)) + 'B' : Math.sign(num) * Math.abs(num)
}
function tFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000000000).toFixed(1)) + 'T' : Math.sign(num) * Math.abs(num)
}
function qFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000000000000000).toFixed(1)) + 'Q' : Math.sign(num) * Math.abs(num)
}

export const formatNumber = (num) => {
  if(!num) num = 0
  if (num <= 999999) {
    return kFormatter(num)
  } else if (num >= 1000000 && num <= 999999999) {
    return mFormatter(num)
  } else if (num >= 1000000000 && num <= 999999999999) {
    return bFormatter(num)
  } else if (num >= 1000000000000 && num <= 999999999999999) {
    return tFormatter(num)
  } else {
    return qFormatter(num)
  }
}
