import React, { useState } from "react";
import { Container } from "react-bootstrap";

import PageLoader from "components/PageLoader";
import Menu from "components/Menu";

import Tracker from "./components/Tracker";
import DogeTracker from "./components/DogeTracker";
import Rewards from "./components/Rewards";
import Prediction from "./components/Prediction";
import Activity from "./components/Activity";
import BabyDogeInfo from "./components/BabyDogeInfo";
import Tokenomics from "./components/Tokenomics";

import { useAddress, useSetUser, useFetchUserData } from "store/hooks";

const PAW = () => {
  const [isShow, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const address = useAddress();

  const { setInitialState } = useSetUser();
  const {
    doge,
    rewards,
    prediction,
    activity,
    price,
    dailyRate,
    token,
    isLoading,
  } = useFetchUserData(address);

  const handleReset = () => {
    setLoading(true);
    setShow(true);
    setInitialState();
    setTimeout(() => setLoading(false), 1000);
  };

  if (loading) {
    return (
      <Container className="paw_panel__container">
        <PageLoader />
      </Container>
    );
  }
  if (isShow) {
    return (
      <Container className="paw__container">
        <Tracker setShow={setShow} />
      </Container>
    );
  }
  return (
    <Container className="paw_panel__container">
      {address && (
        <>
          {" "}
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              <Menu setShow={handleReset} />
              <DogeTracker doge={doge} />
              <Rewards rewards={rewards} />
              <BabyDogeInfo doge={doge} account={address} />
              <Tokenomics token={token} />
              <div className="prediction-activity">
                <div className="flex-1">
                  <Prediction
                    prediction={prediction}
                    price={price}
                    dailyRate={dailyRate}
                    doge={doge}
                  />
                </div>
                <div className="flex-1">
                  <Activity activity={activity} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default PAW;
