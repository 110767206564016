//import axios from "axios";
import { BabyDogeAddress } from "config/abi/babydoge";
import { provider } from "./provider";

export const DEAD_ADDRESS = "0x000000000000000000000000000000000000dead";
export const API_KEY = "7UG6HX1VNW76Z8FK2A9B5FTHU9QCQM9W68";

export const balanceWithoutRewardsFunction = (balance, reflections) => {
  if (balance <= reflections) {
    return 0;
  } else {
    return balance - reflections;
  }
};

export const balance = async (babyDogeContract, account) => {
  const bal = await babyDogeContract.balanceOf(account);
  const decimals = await babyDogeContract.decimals();
  let NumBal = (bal / 10 ** decimals).toFixed(0);
  return NumBal.toLocaleString("fullwide", { useGrouping: false });
};

// https://api.coingecko.com/api/v3/coins/baby-doge-coin/market_chart?vs_currency=usd&days=30&interval=daily

export const VolumeMonth = async () => {
  let url =
    "https://api.coingecko.com/api/v3/coins/baby-doge-coin/market_chart?vs_currency=usd&days=29&interval=daily";
  let header = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const response = await fetch(url, header);
  const totalVolume = await response.json();
  const totalVolumeArray = totalVolume.total_volumes;
  //console.log(totalVolume)
  let monthlyVolume = 0;

  for (let i = 0; totalVolumeArray.length - 1 >= i; i++) {
    //console.log(totalVolumeArray[i][1], i)
    monthlyVolume = monthlyVolume + totalVolumeArray[i][1];
  }

  return monthlyVolume;
};

export const Volume7 = async () => {
  let url =
    "https://api.coingecko.com/api/v3/coins/baby-doge-coin/market_chart?vs_currency=usd&days=6&interval=daily";
  let header = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const response = await fetch(url, header);
  const totalVolume = await response.json();
  const totalVolumeArray = totalVolume.total_volumes;
  //console.log(totalVolume)
  let weeklyVolume = 0;

  for (let i = 0; totalVolumeArray.length - 1 >= i; i++) {
    //console.log(totalVolumeArray[i][1], i)
    weeklyVolume = weeklyVolume + totalVolumeArray[i][1];
  }

  return weeklyVolume;
};

export const Volume24 = async () => {
  let url =
    "https://api.coingecko.com/api/v3/coins/baby-doge-coin/market_chart?vs_currency=usd&days=0&interval=daily";
  let header = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const response = await fetch(url, header);
  const totalVolume = await response.json();
  const totalVolumeArray = totalVolume.total_volumes;
  //console.log(totalVolume)
  let todayVolume = 0;

  for (let i = 0; totalVolumeArray.length - 1 >= i; i++) {
    //console.log(totalVolumeArray[i][1], i)
    todayVolume = todayVolume + totalVolumeArray[i][1];
  }

  return todayVolume;
};

export const getCurrentPrice = async () => {
  let url = "https://api.pancakeswap.info/api/v2/tokens/" + BabyDogeAddress;
  let header = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const response = await fetch(url, header);
  const tokenInfo = await response.json();
  let rawPrice = parseFloat(tokenInfo.data.price);
  return rawPrice.toFixed(14);
};

export const totalSupply = async (babyDogeContract) => {
  let totalSupply = await babyDogeContract.totalSupply();

  return totalSupply;
};

export const MarketCap = async (babyDogeContract, price) => {
  const deadBal = await babyDogeContract.balanceOf(
    "0x000000000000000000000000000000000000dead"
  );
  const decimals = await babyDogeContract.decimals();
  const totalSupply = await babyDogeContract.totalSupply();

  const circulatingSupply = (totalSupply - deadBal) / 10 ** decimals;

  const MarketCapUsd = price * circulatingSupply;

  return MarketCapUsd;
};

export const getRateOfPool = async (
  babyDogeContract,
  userAddress,
  pairAddress
) => {
  const supply =
    (await totalSupply(babyDogeContract, BabyDogeAddress)) / 10 ** 9;
  let balance = (await babyDogeContract.balanceOf(userAddress)) / 10 ** 9;
  let rateOfPool = balance / supply;

  console.log("total supply", supply);
  console.log("balance", balance);
  console.log("rateOfPool", rateOfPool);

  return rateOfPool;
};

export const EstimatedFutureReflections = async (
  days,
  multiplyer,
  dayVolume,
  weekVolume,
  monthVolume
) => {
  if (days < 7) {
    let dayPredict = dayVolume * days * multiplyer;
    return dayPredict;
  } else if (days < 30 && days >= 7) {
    let weekPredict = (days / 7) * weekVolume * multiplyer;
    return weekPredict;
  } else if ((days) => 30) {
    let monthPredict = (days / 30) * monthVolume * multiplyer;
    return monthPredict;
  }
};

export const getCirculatingSupply = async (babyDogeContract, deadBalance) => {
  const vestedBalance = await balance(
    babyDogeContract,
    "0xeaed594b5926a7d5fbbc61985390baaf936a6b8d"
  );
  const deployer = await balance(
    babyDogeContract,
    "0xa4a6db60a345e40f389792952149b2d1255b9542"
  );

  const totalSupplyValue = await totalSupply(babyDogeContract);
  const totalSupplyTrue = totalSupplyValue / 10 ** 9;
  const circulatingSupply =
    totalSupplyTrue - deployer - vestedBalance - deadBalance;

  return circulatingSupply;
};
export const getDeadRate = async (babyDogeContract) => {
  const deadBalance = await balance(babyDogeContract, DEAD_ADDRESS);

  const circulatingSupply = await getCirculatingSupply(
    babyDogeContract,
    deadBalance
  );
  const rate = deadBalance / circulatingSupply;

  return rate;
};
export const getAllBurned = async (_startBlock, _endBlock) => {
  const url = `https://api.bscscan.com/api?module=account&action=tokentx&contractaddress=${BabyDogeAddress}&address=${DEAD_ADDRESS}&page=1&offset=10000&startblock=${_startBlock}&endblock=${_endBlock}&sort=asc&apikey=${API_KEY}`;

  const response = await fetch(url);
  const totalTransfers = await response.json();
  const totalTransfersArray = totalTransfers.result;

  let burnedInPeriod = 0;
  for (let i = 0; totalTransfersArray.length - 1 >= i; i++) {
    if (totalTransfersArray[i].to === DEAD_ADDRESS) {
      burnedInPeriod += totalTransfersArray[i].value / 10 ** 9;
    }
  }

  return burnedInPeriod;
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getReflectionInfo = async (babyDogeContract, _time) => {
  try {
    const blockNumber = await provider.getBlockNumber();
    const blockNumberPast = blockNumber - _time;

    const balance1 = await babyDogeContract.balanceOf(
      "0x000000000000000000000000000000000000dead"
    );

    const balance2 = await babyDogeContract.balanceOf(
      "0x000000000000000000000000000000000000dead",
      { blockTag: blockNumberPast }
    );

    const balance1Fixed = balance1 / 10 ** 9;
    const balance2Fixed = balance2 / 10 ** 9;
    const rate = await getDeadRate(babyDogeContract);

    const burnedInTime = await getAllBurned(blockNumber - _time, blockNumber);
    let reflectionsInThatTime;

    if (balance1Fixed > balance2Fixed) {
      reflectionsInThatTime =
        (balance1Fixed - balance2Fixed) / rate - burnedInTime;
      return Math.abs(reflectionsInThatTime);
    } else {
      reflectionsInThatTime =
        (balance2Fixed - balance1Fixed) / rate - burnedInTime;
      return Math.abs(reflectionsInThatTime);
    }
  } catch (err) {
    return null;
  }

  // try {
  //   let blockNumber = await provider.getBlockNumber();
  //   let blockNumberPast = blockNumber - _time;
  //   let url =
  //     "https://api.bscscan.com/api?module=account&action=tokenbalancehistory&contractaddress=" +
  //     BabyDogeAddress +
  //     "&address=0x000000000000000000000000000000000000dead&blockno=" +
  //     blockNumberPast +
  //     "&apikey=" +
  //     API_KEY;

  //   let header = {
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };
  //   const response = await fetch(url, header);
  //   const balanceAtTime = await response.json();
  //   console.log("Balance At that time", balanceAtTime.result);

  //   const balance1 = await babyDogeContract.balanceOf(
  //     "0x000000000000000000000000000000000000dead"
  //   );

  //   const balance2 = parseInt(balanceAtTime.result);

  //   const balance1Fixed = balance1 / 10 ** 9;
  //   const balance2Fixed = balance2 / 10 ** 9;
  //   const rate = await getDeadRate(babyDogeContract);

  //   const burnedInTime = await getAllBurned(blockNumber - _time, blockNumber);

  //   const reflectionsInThatTime =
  //     (balance1Fixed - balance2Fixed) / rate - burnedInTime;
  //   return reflectionsInThatTime;
  // } catch (err) {
  //   return null;
  // }
};
