export const WEEKLY_BABYDOGE = "Projection based on token trade volume from the past 7 days"
export const MONTHLY_BABYDOGE = "Projection based on token trade volume from the past 30 days"
export const WEEKLY_USD = "Projection based on trade volume in USD from the past 7 days"
export const MONTHLY_USD = "Projection based on trade volume in USD from the past 30 days"
export const APY = "Annual Percent Yield APY Formula (1 + Daily % interest ^ {number of days, 365} - 1"
export const VOLUME_MULTIPLIER="Uses past volume data to predict future rewards, e.g. 1 means current volume, 2 means two times the current volume. 0.5 means half the current volume."
export const DOGE_IN="All purchases, rewards and transfers sent into the account"
export const DOGE_OUT="All sells or transfers sent out of the account"
export const WITHOUT_REWARDS="This shows what your current token balance is without any rewards"

export const BITQUERY_API = 'https://graphql.bitquery.io'