import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useRefresh  from 'hooks/useRefresh'

import {
  setAddress,
  setDays,
  setMultiplier,
  setPrediction,
  setInitialState,
  fetchUserDataAsync,
  fetchTokenDataAsync,
  push as pushToast
} from './actions'
import { fetchTokenSupplyDataAsync } from './user'

// Toasts
export const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast) => dispatch(pushToast(toast))

    return {
      toastError: (title, description) => {
        return push({ type: 'Error', title, description })
      },
      toastInfo: (title, description) => {
        return push({ type: 'Info', title, description })
      },
      toastSuccess: (title, description) => {
        return push({ type: 'Success', title, description })
      },
      toastWarning: (title, description) => {
        return push({ type: 'Warning', title, description })
      },
      push
    }
  }, [dispatch])

  return helpers
}

// User

export const useAddress = () => {
  const { address } = useSelector((state) => state.user)
  return address
}

export const usePrediction = () => {
  const { multiplier, days } = useSelector((state) => state.user)
  return { days, multiplier }
}

export const useFetchUserData = (address) => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  const { days, multiplier } = useSelector((state) => state.user)
  useEffect(() => {
    if (address)
      dispatch(fetchUserDataAsync(address, days, multiplier))
  // eslint-disable-next-line
  }, [address, dispatch])

  useEffect(() => {
    dispatch(fetchTokenDataAsync())
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchTokenSupplyDataAsync())
    // eslint-disable-next-line
  }, [dispatch, fastRefresh])

  const { 
    doge, 
    rewards, 
    prediction, 
    activity, 
    dailyRate, 
    price,
    isLoading, 
    token,
  } = useSelector((state) => state.user)

  return { doge, rewards, prediction, activity, dailyRate, price, isLoading, token }
}

export const useSetUser = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    return {
      setAddress: (address) => dispatch(setAddress(address)),
      setDays: (days) => dispatch(setDays(days)),
      setMultiplier: (multiplier) => dispatch(setMultiplier(multiplier)),
      setInitialState: () => dispatch(setInitialState()),
      setPrediction: (reflections, value) => dispatch(setPrediction(
        {
          doge_gained: reflections, 
          estimated_usd_value: value}
      )),
    }
  }, [dispatch])

  return helpers
}