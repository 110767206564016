import { ethers } from "ethers";

export const archProvider = new ethers.providers.JsonRpcProvider(
  "https://speedy-nodes-nyc.moralis.io/1c2baaae7c0d11120337ddc1/bsc/mainnet/archive"
);

export const provider = new ethers.providers.JsonRpcProvider(
  "https://bsc-dataseed.binance.org/"
);
export default provider;
