import React, { useEffect, useRef } from 'react'
import CountUp from 'react-countup'

const Balance = ({
  value,
  color = 'text',
  decimals = 0,
  duration = 1,
  unit,
  prefix = '',
  ...props
}) => {
  const previousValue = useRef(0)

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return (
    <div {...props} className="property text-align-left">
      <CountUp
        start={previousValue.current}
        end={value}
        prefix={prefix}
        suffix={unit}
        decimals={decimals}
        duration={duration}
        separator=","
      />
    </div>
  )
}

export default Balance
