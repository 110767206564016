import React from 'react'

import { Card, Row, Col, Image } from 'react-bootstrap'

import Tooltip from 'components/ToolTip'

import { formatNumber } from 'utils/formatBalance'
import { WEEKLY_BABYDOGE, WEEKLY_USD, MONTHLY_BABYDOGE, MONTHLY_USD } from 'config/constants'

const Rewards = ({ rewards }) => {
  return (
    <Row className="doge mx-2 my-4">
      <Col className="text-align">
        <Image
          src=""
          width="96"
          alt="Icon Rewards"
          rounded />
      </Col>
      <div className="doge__title">Rewards</div>
      <Row>
        <Col className="doge__sub-title">
          Baby Doge features a 5% fee that is redistributed as rewards to holders. That means the more volume and more active the community is the more Baby Doge Coins you’ll receive!
        </Col>
      </Row>
      <Card className="doge__card">
        <Row xs="1" sm="2">
          <Col>
            <Row>
              <Col>
                <div className="text-align property">Total BabyDoge Earned</div>
                <div className="text-align doge__price">{formatNumber(rewards.total_doge_earned)}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-align property">
                  Projected Weekly BabyDoge Rewards
                  <Tooltip placement="bottom" content={WEEKLY_BABYDOGE} />
                </div>
                <div className="text-align doge__price">{formatNumber(rewards.weekly_doge_reward)}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-align property">
                  Projected Monthly BabyDoge Rewards
                  <Tooltip placement="bottom" content={MONTHLY_BABYDOGE} />
                </div>
                <div className="text-align doge__price">{formatNumber(rewards.monthly_doge_reward)}</div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <div className="text-align property">Total USD Value Earned</div>
                <div className="text-align doge__price">${formatNumber(rewards.total_usd_earned)}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-align property">
                  Projected Weekly USD Rewards
                  <Tooltip placement="bottom" content={WEEKLY_USD} />
                </div>
                <div className="text-align doge__price">${formatNumber(rewards.weekly_usd_reward)}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-align property">
                  Projected Monthly USD Rewards
                  <Tooltip placement="bottom" content={MONTHLY_USD} />
                </div>
                <div className="text-align doge__price">${formatNumber(rewards.monthly_usd_reward)}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Row>
  )
}

export default Rewards
